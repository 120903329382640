<template>

  <Head :title="title" />
  <v-layout class="rounded">
    <v-app-bar density="compact" scroll-behavior="fade-image" image="https://picsum.photos/1920/1080?random" extended>
      <template v-slot:image>
        <v-img gradient="to top right, rgba(19,84,122,.8), rgba(128,208,199,.8)"></v-img>
      </template>
      <!-- <template v-slot:prepend>
        <v-app-bar-nav-icon @click.stop.prevent="drawer = !drawer" v-show="$user && $user.id"></v-app-bar-nav-icon>
      </template> -->
      <!-- <v-toolbar-title>Ageare</v-toolbar-title> -->
      <template #extension>
        {{ systemSettings?.title }}
      </template>
      <v-toolbar-items>
        <v-btn icon="mdi-home" :href="`/`" @click.prevent="goTo(`/`)"></v-btn>
      </v-toolbar-items>

      <v-toolbar-items>
        <v-btn size="small" text href="/dashboard" @click.prevent.stop="goTo('/dashboard')">Dashboard</v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <v-toolbar-items v-show="$user && $user.id">
        <v-dialog max-width="550">
          <template v-slot:activator="{ props: activatorProps }">
            <v-btn v-bind="activatorProps" icon="mdi-help-circle-outline" variant="text"></v-btn>
          </template>

          <template v-slot:default="{ isActive }">
            <v-card title="Application Help menu">
              <v-card-text>
                <helper-box></helper-box>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn size="small" @click="isActive.value = false" text="Close"></v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-toolbar-items>

      <v-toolbar-items v-if="$user">
        <v-menu v-if="$page.props.jetstream.hasTeamFeatures">
          <template v-slot:activator="{ props }">
            <v-btn small text v-bind="props">
              {{ $user.current_team.name }}
            </v-btn>
          </template>
          <v-list>
            <v-list-subheader>Manage Team</v-list-subheader>
            <v-list-item :href="route('teams.show', $user.current_team)"
              @click.stop.prevent="goTo(route('teams.show', $user.current_team))">
              <v-list-item-title>Team Settings</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="$page.props.jetstream.canCreateTeams" :href="route('teams.create')"
              @click.stop.prevent="goTo(route('teams.create'))">
              Create New Team
            </v-list-item>

            <!-- Team Switcher -->
            <template v-if="$user.all_teams.length > 1">
              <div class="border-t border-gray-200 dark:border-gray-600" />

              <div class="block px-4 py-2 text-xs text-gray-400">
                Switch Teams
              </div>

              <template v-for="team in $user.all_teams" :key="team.id">
                <form @submit.prevent="switchToTeam(team)">
                  <v-btn size="small" variant="text" type="submit" prepend-icon="mdi-content-save-outline">
                    <div class="flex items-center">
                      <svg v-if="team.id == $user.current_team_id" class="me-2 h-5 w-5 text-green-400"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>

                      <div>{{ team.name }}</div>
                    </div>
                  </v-btn>
                </form>
              </template>
            </template>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <v-toolbar-items>
        <user-menu></user-menu>
      </v-toolbar-items>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" v-if="$user && $user.id">
      <v-list density="compact">
        <v-list-subheader>Menu</v-list-subheader>
        <v-list-item title="Property Owners" subtitle="Manage Property Owners" href="/landlords"
          @click.prevent.stop="goTo('/landlords')" prepend-icon="mdi-account-multiple"
          :active="route().current('landlords.*')"></v-list-item>
        <v-list-group value="Houses">
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" prepend-icon="mdi-store-outline" title="Properties"></v-list-item>
          </template>
          <v-list-item title="Properties" subtitle="Manage houses" href="/houses" @click.prevent.stop="goTo('/houses')"
            prepend-icon="mdi-warehouse" :active="route().current('houses.*')"></v-list-item>
          <v-list-item title="Rental Units" subtitle="" href="/units" @click.prevent.stop="goTo('/units')"
            prepend-icon="mdi-home" :active="route().current('units.*')"></v-list-item>
        </v-list-group>
        <v-list-item title="Tenants" subtitle="Manage Tenants" href="/tenants" @click.prevent.stop="goTo('/tenants')"
          prepend-icon="mdi-account-supervisor-circle-outline" :active="route().current('tenants.*')"></v-list-item>
        <v-divider></v-divider>
        <v-list-group value="Tenancy">
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" prepend-icon="mdi-account-supervisor-circle-outline mdi-flip-h"
              title="Tenancies"></v-list-item>
          </template>
          <v-list-item title="Tenancy" subtitle="Manage Tenants" href="/tenancies"
            @click.prevent.stop="goTo('/tenancies')" rounded="lg"
            prepend-icon="mdi-account-supervisor-circle-outline mdi-flip-h"
            :active="route().current('tenancies.*')"></v-list-item>
          <v-list-item title="Deposits" subtitle="Rent security deposits" href="/deposits"
            @click.prevent.stop="goTo('/deposits')" rounded="lg" prepend-icon="mdi-cash-lock-open mdi-flip-h"
            :active="route().current('deposits.*')"></v-list-item>
          <v-list-item title="Charges" subtitle="Rental charges" href="/charges" @click.prevent.stop="goTo('/charges')"
            prepend-icon="mdi-cash-lock-open" :active="route().current('charges.*')"></v-list-item>
        </v-list-group>
        <v-list-item title="Rents" subtitle="Rents" href="/rents" @click.prevent.stop="goTo('/rents')" rounded="lg"
          prepend-icon="mdi-cash-lock" :active="route().current('rents.*')"></v-list-item>
      </v-list>
    </v-navigation-drawer>
    <Teleport to="body">
      <v-main class="d-flex" style="min-height: calc(100vh - 64px); overflow-y: auto;">
        <SessionMessage />
        <v-container fluid class="pa-0 overflow-hidden">
          <slot name="breadcrumbs"></slot>
          <slot></slot>
        </v-container>
      </v-main>
    </Teleport>
  </v-layout>
</template>

<script setup>

import { ref } from 'vue'
import UserMenu from '@/Layouts/components/userMenu.vue'
import SessionMessage from '@/Mixins/session.vue'
import HelperBox from '@/Components/Help/help.vue'

defineOptions({
  name: 'GuestLayout',
})

let drawer = ref(false)

defineProps({
  title: String,
})

</script>
